import React from "react"
import { Link, graphql } from "gatsby"
import "bootstrap/dist/css/bootstrap.css"
import "./index.css"
import {
  FaEdit
} from "react-icons/fa"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Sidebar from "../components/sidebar/Sidebar"
import NormalTag from "../components/tags/NormalTag"
import Preamble from "../components/Preamble"

const IndexPage = ({ data }) => {
  const posts = data.allMarkdownRemark.edges
  const currentPage = 1
  const postsPerPage = 5 // see limit in graphql query below
  const nextPage = "/" + (currentPage + 1).toString()
  const hasNextPage = data.allMarkdownRemark.totalCount > postsPerPage

  const getNormalTags = (tags) => {
    const normalTags = []
    tags.forEach((tag, i) => {
        normalTags.push(<NormalTag key={i} tag={tag}/>)
    })
    return normalTags
  }

  return (
    <Layout>
      <SEO title="Home" keywords={[`gatsby`, `javascript`, `react`, `web development`, `blog`, `graphql`]} />
      <div className="index-main">
        <div className="sidebar px-4 py-2">
          <Sidebar />
        </div>
        <div className="post-list-main">
          {posts.map((post) => {
            const tags = post.node.frontmatter.tags
            return (
              <div key={post.node.id} className="container mt-5">
                <Link
                  to={post.node.fields.slug}
                  className="text-dark"
                >
                  <h2 className="title">{post.node.frontmatter.title}</h2>
                </Link>
                <small className="d-block text-info"><FaEdit size={20} style={{color: 'gray'}} />{post.node.frontmatter.date}
                </small>
                <Preamble text={post.node.frontmatter.preamble}/>
                <Link
                  to={post.node.fields.slug}
                  className="text-primary"
                >
                  <small className="d-inline-block ml-3"> 本文を読む</small>
                </Link>
                <div className="d-block">
                  {getNormalTags(tags)}
                </div>
              </div>
            )
          })}
          {hasNextPage &&
            <div className="mt-4 text-center">
              <Link to={nextPage} rel="next" style={{ textDecoration: `none` }}>
                <span className="text-dark">次のページ →</span>
              </Link>
            </div>
          }
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
         query IndexQuery {
           site {
             siteMetadata {
               title 
               author
             }
           }
           allMarkdownRemark(
             limit: 5
             sort: { fields: [frontmatter___date], order: DESC }
             filter: { frontmatter: { published: { eq: true } } }
           ) {
             totalCount
             edges {
               node {
                 excerpt(pruneLength: 200)
                 html
                 id
                 frontmatter {
                   title
                   date(formatString: "YYYY/MM/DD")
                   tags
                   preamble
                 }
                 fields {
                   slug
                 }
               }
             }
           }
         }
       `

export default IndexPage

